import "./src/base/styles/main.css";

import React from "react";
import { MatchMediaProvider, LanguageProvider } from "@/context";
import { combineComponents } from "@/base/helpers/combineComponents";

const MainLayoutProvider = combineComponents(
  MatchMediaProvider,
  LanguageProvider
);

export const wrapRootElement = ({ element }) => {
  return <MainLayoutProvider>{element}</MainLayoutProvider>;
};
